:root {
    --color-accent: #751A02;
    --color-accent-secondary: #FFF;
    --red: #751A02;
    --barely-gray: rgb(50, 50, 50);
    --yellow: rgb(214, 155, 56);
    --vh: 1vh;
    --background: rgba(250, 250, 250, 1);
}

* {
    /*overflow: hidden;*/
}

h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: AlfaSlabOne;
    font-weight: normal;
    text-transform: uppercase;
}

h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: AlfaSlabOne;
    font-weight: normal;
    text-transform: uppercase;
}

p {
    margin-top: 7px;
    margin-bototm: 7px;
}

.button {
    padding: 12px 36px;
    background-color: var(--yellow);
    color: white;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin: 10px;
}

.match-screen {
    position: absolute;
    top: calc(10 * var(--vh));
    right: 15px;
    background-color: rgba(20, 20, 20, 0.65);
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 2px 2px 2px rgba(20, 20, 20, 0.55);
    text-color: var(--color-accent);
    z-index: 999;
}

body {
    overflow: hidden !important;
    background-color: rgba(250, 250, 250, 1);
    height: 100vh;
    height: calc(100 * var(--vh));
}

#root {
    overflow: hidden !important;
    height: 100vh;
    height: calc(100 * var(--vh));
}

.app-controls {
    display: flex;
    height: calc(5 * var(--vh));
    padding: 10px 0;
    justify-content: space-around;
    position: relative;
    background-color: white;
    border-bottom: 1px solid rgba(240, 240, 240, 0.8);
    box-shadow: 0 1px 1px rgba(140, 140, 140, 0.9);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 98vw;
    margin: 0 auto;
}

.app-controls > svg {
    height: 100%;
    width: auto;
}

.app-controls > svg:nth-of-type(1) {
    position: absolute;
    transform: scale(0.45);
    left: 5px;
    top: 0;
}

.demo-overlay {
    height: calc(100 * var(--vh));
    width: 100vw;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
}

.demo-overlay > svg {
    position: absolute;
    width: 25vw;
}

.favorites-demo-swipeleft {
    left: 5px;
    width: 19vw !important;
    height: auto;
}

.favorites-demo-arrow {
    left: 50%;
    height: 25vh;
    width: 100% !important;
    transform: translate(-50%, 0);
}

.favorites-demo-tap-row {
    left: 42vw;
    width: 15vw !important;
    height: auto;
}

.favorites-demo-swiperight {
    right: -5px;
    width: 19vw !important;
    height: auto;
}

.no-favorites-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(20 * var(--vh));
    color: gray;
}

.no-favorites-row > a.button {
    font-size: 14px;
    padding: 10px 18px;
}

.demo-swipeleft {
    left: 5px;
}

.demo-tapanywhere {
    left: 50vw;
    bottom: 20vh;
    margin-left: calc(-1 * 12.5vw);
}

.demo-swiperight {
    right: 5px;
}

.demo-highlights {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 5px;
    width: 95vw;
    left: 2.5vw;
    height: auto;
    align-items: flex-end;
}

.demo-highlight {
    width: 17vw;
    overflow: visible;
}

.app-menu {
    position: absolute;
    top: 0;
    height: calc(100 * var(--vh));
    width: 100vw;
    background-color: rgba(40, 40, 40, 0.9);
    z-index: 999;
}

.drawer {
    top: 0;
    position: absolute;
    z-index: 1000;
    height: calc(100 * var(--vh));
    width: 60vw;
    background-color: white;
    animation-name: drawer-slide;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    left: -150px;
    box-shadow: 2px 0 1px rgba(240, 240, 240, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.drawer-app-title svg {
    width: 100%;
}

.drawer-action-row svg {
    color: var(--yellow);
    transform: scale(1.8);
}

.drawer .drawer-app-title {
    color: rgba(180, 180, 180, 0.95);
    text-align: center;
    border-bottom: 2px solid rgba(240, 240, 240, 0.95);
    padding: 10px;
    font-weight: bold;
}

.drawer .drawer-action-row {
    display: flex;
    align-items: center;
    text-align: end;
    font-size: 14px;
    color: gray;
    padding: 20px 12px 20px 12px;
    font-weight: normal;
    border-bottom: 2px solid rgba(240, 240, 240, 0.95);
    margin: 3px 0;
    transition: bottom 0.2s;
    background-color: rgba(245, 245, 245, 0.55);
    text-transform: uppercase;
    font-weight: 700;
    color: black;
    font-size: 16px;
}

.drawer .drawer-action-row:active {
    position: relative;
    bottom: -2px;
    border-bottom: 2px solid transparent;
}

.drawer .drawer-action-row > span {
    flex: 1;
    text-align: end;
}

.drawer .drawer-action-row > svg {
    fill: gray;
    height: 18px;
}

.drawer .drawer-logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    color: black;
    font-weight: 700;
    font-size: 14px;
}

.drawer-logo > svg {
    width: 25%;
    height: auto;
    color: gray;
    margin: 10px;
}

@keyframes drawer-slide {
    0% {
        left: -150px;
    }
    100% {
        left: -1vw;
    }
}

.queue-view {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.queue-view > div.no-rings-message {
    color: rgba(160, 160, 160, 1);
    text-align: center;
    font-size: 14px;
    height: 90vh;
    height: calc(90 * var(--vh));
    display: flex;
    flex-direction: column;
    justify-content: center;
}

svg {
    height: 100%;
}

a {
    color: unset;
    text-decoration: none;
}

.fade-in {
    animation-name: fade-in;
}

.snappy-fade-in {
    animation-name: snappy-fade-in;
}

.t0p3s {
    animation-duration: 0.3s;
}

.t0p5s {
    animation-duration: 0.5s;
}

.t2s {
    animation-duration: 2s;
}

.t4s {
    animation-duration: 4s;
}

.t6s {
    animation-duration: 6s;
}

.t8s {
    animation-duration: 8s;
}

.t10s {
    animation-duration: 10s;
}

.display-none {
    display: none !important;
}

.display-initial {
    display: initial;
}

@keyframes snappy-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0
    }
    65% {
        opacity: 0;
    }
    70% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}

.opacity-0 {
    opacity: 0;
}

.queue-controls {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 95vw;
    left: 2.5vw;
    bottom: 10px;
}

.queue-controls .control-active {
    background-color: var(--yellow);
}

.queue-controls .control-active svg {
    color: white !important;
}


.shop-button {
    bottom: -100px;
    right: 8px;
    transition: bottom 0.2s;
    position: absolute;
    background-color: var(--yellow);
    color: white;
    border-radius: 50%;
    box-shadow: -1px 1px 2px rgba(200, 200, 200, 0.3);
    height: 13vw;
    width: 13vw;
    display:flex;
    justify-content: center;
    align-items: center;
}

.shop-button svg {
    width:60%;
    height:60%;
    margin-right: 2px;
}

.ring-card.card-open .shop-button {
    bottom: calc(40 * var(--vh) + 8px);
}

.queue-control {
    position: relative;
    border-radius: 50%;
    box-shadow: -1px 1px 4px rgba(200, 200, 200, 0.8);
    height: 15vw;
    width: 15vw;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.queue-control:active {
    animation-name: flatten;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    left: 1px;
    top: 1px;
}

@keyframes flatten {
    0% {
        box-shadow: -1px 1px 4px rgba(200, 200, 200, 0.8);
    }
    100% {
        box-shadow: -1px 1px 4px rgba(200, 200, 200, 0);
    }
}

@keyframes flatten-down {
    0% {
        box-shadow: 0 4px 2px -2px rgba(200, 200, 200, 0.8);
    }
    100% {
        box-shadow: 0 4px 2px -2px rgba(200, 200, 200, 0);
    }
}

.ring-card {
    border-radius: 10px;
    box-shadow: -1px 1px 2px rgba(200, 200, 200, 0.6);
    height: 80vh;
    height: calc(80 * var(--vh));
    width: 98vw;
    position: absolute;
    top: 0.75vh;
    top: calc(0.75 * var(--vh));
    left: 0.75vw;
    font-family: 'BentonSans';
    color: white;
    font-size: 24px;
    background-color: white;
    overflow: hidden;
}

.card-title {
    max-width: 90vw;
}

.card-flavor {
    max-width: 90vw;
}

.card-errata {
    max-width: 90vw;
}

.queue-view > .ring-card {
    display: none;
}

.queue-view > .ring-card:nth-last-child(-n+4) {
    display: initial;
}

.ring-card.card-open {
    background-color: white;
}

.ring-card.card-open img {
    height: 40vh;
    height: calc(40 * var(--vh));
}

.ring-card.card-open .card-summary {
    color: black;
    position: relative;
    bottom: 0;
    top: 10px;
    transition: color 0.2s, padding 0.2s, bottom 0.2s;
    background-image: none;
    padding: 0 0 calc(2 * var(--vh)) 6vw;
    animation-name: none;
}

@keyframes animate-gradient {
    0% {
        opacity: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0));

    }
    100% {
        opacity: 1;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1));
    }
}

.card-summary {
    position: absolute;
    width: 100vw;
    bottom: 0;
    display: flex;
    flex-direction: column;
    transition: color 0.2s, bottom 0.5s;
    box-sizing: border-box;
    padding: calc(10 * var(--vh)) 0 calc(6 * var(--vh)) 6vw;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1));
}

.card-summary.animate-gradient {
    animation-name: animate-gradient;
    animation-duration: 0.35s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.ring-card .card-bio {
    display: none;
}

.ring-card .card-bio-hint {
    position: absolute;
    bottom: 10px;
    width: 40%;
    height: 3px;
    background-color: var(--background);
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
}

.ring-card.card-open .card-bio {
    position: relative;
    color: black;
    left: 6vw;
    top: 10px;
    font-size: 18px;
    width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}

.ring-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    border-radius: inherit;
    transition: height 0.2s ease-out;
}

.ring-card.card-open img {
    border-bottom: 1px inset var(--background);
}

.ring-card .image-dots {
    position: absolute;
    bottom: calc((40 * var(--vh)) + 10px);
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
}

.ring-card .image-dot {
    height: 8px;
    width: 8px;
    margin-right: 5px;
    border-radius: 50%;
    border: 2px solid gray;
    box-sizing: border-box;
    background-color: white;
    transition: width 0.2s, height 0.2s, border-top-color 0.1s ease-out, border-right-color 0.15s ease-out, border-bottom-color 0.2s ease-out, border-left-color 0.25s ease-out;
}

.ring-card .image-dot.dot-active {
    border: 2px solid transparent;
    background-color: white;
}

.card-title > span {
    font-family: 'AlfaSlabOne';
    font-size: 30px;
    font-weight: lighter;
}

.card-material {
    text-transform: capitalize;
    font-style: italic;
    align-self: flex-end;
}

.card-errata {
    margin-top: 5px;
    width: 90vw;
    display: flex;
    justify-content: space-between;
}

.card-price {
    font-size: 18px;
    align-self: flex-end;
    margin-bottom: 2px;
    color: var(--red);
}

.profile {
    max-height: 95vh;
    max-height: calc(95 * var(--vh));
    overflow-y: scroll;
    overflow-x: hidden;
}

.favorites {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.favorites-navigation {
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 100%;
    color: var(--color-accent);
    font-weight: 700;
    margin-bottom: 5px;
}

.favorites-navigation .counter {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    height: 18px;
    width: 18px;
    color: var(--color-accent-secondary);
    background-color: var(--color-accent);
    margin-left: 10px;
}

.favorite-rows {
    overflow-x: hidden;
    width: 100vw;
    overflow-y: scroll;
}

.match-star {
    height: 18px;
    fill: var(--color-accent);
    flex: 1;
}

.favorite-drawer-open {
    border-radius: 8px;
    box-shadow: rgba(27, 31, 35, 0.25) 0px 2px 2px;
}

.favorite-drawer-right {
    transform: translate(-80px);
}

.favorite-drawer-left {
    transform: translate(80px);
}

.favorite-row-wrapper {
    position: relative;
    margin-bottom: 10px;
}

.favorite-row {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    transition: none;
    background-color: var(--background);
    z-index: 1;
    height: 100%;
}

.favorite-row-shop, .favorite-row-remove {
    top: 0;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.favorite-row-shop > svg, .favorite-row-remove > svg {
    transform: scale(1.1);
}

.favorite-row-shop {
    margin-right: 6px;
    width: 86px;
    background-color: var(--yellow);
    position: absolute;
    left: 0;
}

.favorite-row-remove {
    margin-left: 6px;
    width: 86px;
    background-color: var(--red);
    position: absolute;
    right: 0;
}

.favorite-gutter {
    width: 13vw;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.favorite-gutter svg {
    width: 70%;
}

.favorite-content {
    display: inline-flex;
}

.favorite-image {
    height: 64px;
    width: 64px;
    padding: 8px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 1px 1px 1px rgba(220, 220, 220, 1);
    margin: 0 5vw 0 0;
}

.favorite-image img {
    width: 100%;
}

.favorite-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;
}

.favorite-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: bold;
    color: rgba(10, 10, 10, 0.65);
}

.favorite-materials {
    font-size: 14px;
    font-weight: 600;
}

.favorite-price {
    font-size: 13px;
    color: gray;
}

.manly-divider {
    width: 100vw;
    position: relative;
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.manly-divider div {
    position: absolute;
    border-bottom: 2px solid var(--color-accent);
    width: 50%;
}

.manly-divider svg {
    z-index: 1;
    width: 24px;
    height: 24px;
    color: var(--color-accent);
    background-color: white;
}

.sharing-header {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--red);
    text-transform: uppercase;
}

.modal-wrapper {
    position: absolute;
    height: calc(100 * var(--vh));
    top: 0;
    width: 100vw;
    background-color: rgba(30, 30, 30, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal {
    position: relative;
    background-color: white;
    width: 85vw;
    padding: 15px;
    text-align: center;
    border: 5px solid var(--yellow);
}

.modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sharing-header span:nth-of-type(1) {
    font-size: 20px;
    font-weight: bold;
}

.modal span {
    margin: 10px 0 10px 0;
    font-size: 18px;
}

.sharing-form {
    margin: 10px 0 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.sharing-form input:not([type=checkbox]) {
    width: 75%;
    font-size: 16px;
    padding: 16px;
    border: none;
    backface-visibility: hidden;
    background-color: rgba(240, 240, 240, 0.95);
    margin: 5px 0 5px 0;
}

.sharing-form input[type=submit] {
    background-color: var(--yellow);
    color: white;
    font-size: 24px;
    padding: 12px 56px;
    width: unset;
    text-transform: uppercase;
    font-weight: bold;
    font-family: RobotoCondensed;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.sharing-form input[type=submit].disabled {
    background-color: gray;
}

.sharing-form .form-error {
    position: absolute;
    bottom: -18px;
    font-size: 14px;
    color: var(--color-accent);
}

.sharing-form .input-focus {
    display: block;
}

.sharing-warranty {
    width: 70%;
    padding-top: 16px;
    margin: 10px;
    border-top: 2px solid var(--red);
}

.sharing-warranty span {
    font-size: 16px;
}

.flip-away {
    animation-name: flip-away;
    backface-visibility: hidden;
    animation-duration: 0.25s;
}

@keyframes flip-away {
    0% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(360deg);
    }
}

.sharing-form .next-input {
    position: absolute;
    right: 5px;
    fill: gray;
    z-index: 1;
}

.sharing-form .last-input {
    position: absolute;
    left: 5px;
    fill: gray;
    transform: rotate(180deg);
    z-index: 1;
}

.sharing-form .sharing-submit {
    fill: var(--color-accent);
    position: absolute;
    right: 5px;
    z-index: 1;
}

.sharing-header .button.disabled {
    background-color: gray;
}

.sharing-form input:focus {
    outline: none;
}

.profile-image {
    width: 25vw;
    height: 25vw;
    padding: 6px;
    border-radius: 50%;
    border: 6px inset var(--yellow);
    background-color: #f0f0f0;
    margin: 10px 0 10px 0;
}

@font-face {
    font-family: 'RobotoCondensed';
    src: url('fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
    font-family: 'RobotoCondensed';
    src: url('fonts/RobotoCondensed-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'AlfaSlabOne';
    src: url('fonts/AlfaSlabOne-Regular.ttf');
}

@font-face {
    font-family: 'BentonSans';
    src: url('fonts/BentonSans-Comp-Regular.woff');
}

@font-face {
    font-family: 'BentonSansBook';
    src: url('fonts/BentonSans-Comp-Bold.woff');
    font-weight: bold;
}

.close-modal {
    position: absolute;
    top: -10px;
    right: -10px;
    height: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: var(--red);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.close-modal svg {
    width: 14px;
    height: 14px;
    color: white;
}

.grecaptcha-badge {
    visibility: hidden;
}

/* We have some scaling issues for these devices. These rules patch them */
@media screen and (max-width: 400px) {
    h1 {
        font-size: 24px
    }

    h3 {
        font-size: 14px
    }

    span {
        font-size: 12px !important
    }

    input {
        font-size: 12px !important
    }

    .sharing-form input {
        padding: 8px !important
    }

    .modal {
        width: 80vw !important
    }

    .card-title span {
        font-size: 30px !important
    }

    input[type=submit] {
        padding: 10px 40px !important;
        font-size: 18px !important
    }

    .card-bio {
        font-size: 14px !important;
    }
}